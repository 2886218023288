<template>
  <v-container fluid>
    <v-form ref="formulario">
      <v-row>
        <v-col cols="12">
          <div class="d-flex items-center" style="gap: 10px">
            <v-btn
              large
              color="primary"
              @click.stop="generarEstudio"
              :loading="creating"
            >
              <v-icon left>mdi-calculator</v-icon>
              Generar estudio express</v-btn
            >
          </div>
        </v-col>

        <v-col cols="12" class="d-flex flex-wrap" style="gap: 16px">
          <v-alert
            v-if="!estudio.freezePosition && estudio.cubiertas.length == 0"
            class="mb-0"
            type="error"
            text
            dense
          >
            Sin cubierta seleccionada
          </v-alert>
        </v-col>

        <v-col cols="12" md="7">
          <tarjeta-datos
            titulo="Descripción del proyecto"
            icon="format-list-bulleted"
          >
            <aux-input title="Nombre proyecto (Cliente)">
              <v-text-field
                filled
                dense
                hide-details="auto"
                :rules="[rules.req]"
                v-model="estudio.nombreProyecto"
              ></v-text-field>
            </aux-input>
            <v-divider />
            <aux-input title="Descripción proyecto & observaciones">
              <v-textarea
                filled
                dense
                hide-details="auto"
                no-resize
                v-model="estudio.descripcion"
              ></v-textarea>
            </aux-input>
          </tarjeta-datos>

          <tarjeta-datos class="mt-6" titulo="Datos suministro" icon="domain">
            <aux-input title="CUPS">
              <v-text-field
                dense
                hide-details="auto"
                filled
                placeholder="CUPS"
                v-model="estudio.cups_principal.cups"
                :rules="[rules.cups, rules.req]"
                class="mb-1"
              >
                <template v-slot:append-outer>
                  <v-btn
                    class="mt-n2"
                    color="primary"
                    @click="buscarCups"
                    :loading="isLoading"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </aux-input>

            <!-- <v-divider></v-divider>

            <aux-input title="Simulación comunidad">
              <v-text-field
                placeholder="Nº vecinos"
                filled
                dense
                hide-details
                v-model="estudio.autoestudio.simulacion_vecinos"
                type="number"
              ></v-text-field>
              <v-alert type="info" class="mb-0 mt-2" text dense
                >Se simulará un suministro residencial por cada vecino que se
                sumará al consumo de la comunidad</v-alert
              >
            </aux-input> -->

            <v-divider></v-divider>

            <aux-input title="Dirección">
              <v-text-field
                filled
                dense
                v-model="estudio.cups_principal.direccion"
                hide-details="auto"
                :rules="[rules.req]"
                placeholder="Dirección suministro"
                @change="buscarDireccion"
              >
                <template v-slot:append-outer>
                  <v-btn
                    class="mt-n2"
                    color="primary"
                    @click="buscarDireccion"
                    :loading="isLoading"
                  >
                    <v-icon>mdi-google-maps</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
              <v-alert
                v-if="!estudio.freezePosition"
                type="info"
                class="mb-0 mt-2"
                text
                dense
                >Selecciona la cubierta en el mapa</v-alert
              >
            </aux-input>
          </tarjeta-datos>

          <tarjeta-datos class="mt-6" titulo="Opciones" icon="calculator">
            <template v-slot:titulo>
              <span>Opciones</span>
              <v-spacer />
              <v-alert type="info" dense class="mb-0" text>
                Campos opcionales, en caso de no rellenarse se usarán valores
                por defecto
              </v-alert>
            </template>

            <EstudioExpress :estudio="estudio"
              :idEstudio="estudio.idEstudio"
              ref="estudioExpress"
              v-model="estudio.autoestudio" />

            <!-- <aux-input title="Potencia pico">
              <v-item-group
          class="d-flex align-center flex-wrap mt-2"
          mandatory
          style="gap: 20px"
        >
          <v-item v-slot="{ active, toggle }">
            <v-card
              outlined
              class="pa-2 pa-2 px-4 rounded-md d-flex"
              @click="
                toggle();
                estudio.autoestudio.potencia_solicitada = null;
              "
            >
              <h4>Optimización automática</h4>
            </v-card>
          </v-item>
          <v-item v-slot="{ active, toggle }">
            <v-card
              outlined
              class="pa-2 pa-2 px-4 rounded-md d-flex"
              :disabled="!estudio.cubiertas"
              @click="
                toggle();
                estudio.autoestudio.potencia_solicitada = 'Coste 0';
              "
            >
              <h4>Estudio coste 0</h4>
            </v-card>
          </v-item>
          <v-item v-slot="{ active, toggle }">
            <v-card outlined class="pa-2 rounded-md d-flex">
              <v-text-field
                dense
                hide-details
                filled
                suffix="kWp"
                @input="toggle"
                type="number"
                v-model="estudio.autoestudio.potencia_solicitada"
              ></v-text-field>
            </v-card>
          </v-item>
        </v-item-group>
            </aux-input>
            <v-divider />
            <aux-input title="Curva horaria">
              <v-item-group
                v-model="estudio.autoestudio.curva"
                class="d-flex align-center flex-wrap mt-2"
                style="gap: 20px"
              >
                <v-item
                  :key="curva.value"
                  :value="curva.value"
                  v-for="curva in selects.curvas"
                  v-slot="{ toggle }"
                >
                  <v-card
                    outlined
                    class="item-selection align-center pa-4 ma-1"
                    style="height: 125px; width: 125px; aspect-ratio: 1"
                    @click.stop="toggle"
                  >
                    <h4>{{ curva.text }}</h4>
                    <v-icon large>{{ curva.icon }}</v-icon>
                    <v-sparkline
                      :value="curvas_horarias[curva.value]"
                      color="secondary"
                      line-width="6"
                    ></v-sparkline>
                  </v-card>
                </v-item>
              </v-item-group>
            </aux-input>
            <v-divider />
            <aux-input title="Estructura">
              <v-item-group
                v-model="estudio.autoestudio.estructura"
                class="d-flex align-center flex-wrap mt-2"
                style="gap: 20px"
              >
                <v-item
                  :key="est.value"
                  :value="est.value"
                  v-for="est in selects.estructura"
                  v-slot="{ toggle }"
                >
                  <v-card
                    outlined
                    class="item-selection align-center pa-2 px-4 ma-1"
                    @click.stop="toggle"
                  >
                    <h4>{{ est.text }}</h4>
                  </v-card>
                </v-item>
              </v-item-group>
            </aux-input>
            <v-divider />
            <aux-input title="Superficie">
              <v-item-group
                class="d-flex align-center flex-wrap mt-2"
                mandatory
                style="gap: 20px"
              >
                <v-item v-slot="{ active, toggle }">
                  <v-card
                    outlined
                    class="pa-2 pa-2 px-4 rounded-md d-flex"
                    @click="
                      toggle();
                      estudio.autoestudio.superficie_disponible = null;
                    "
                  >
                    <h4>Indefinida</h4>
                  </v-card>
                </v-item>
                <v-item v-slot="{ active, toggle }">
                  <v-card
                    outlined
                    class="pa-2 pa-2 px-4 rounded-md d-flex"
                    :disabled="!estudio.cubiertas"
                    @click="
                      toggle();
                      estudio.autoestudio.superficie_disponible =
                        superficieDisponible;
                    "
                  >
                    <h4>Según cubiertas marcadas</h4>
                  </v-card>
                </v-item>
                <v-item v-slot="{ active, toggle }">
                  <v-card outlined class="pa-2 rounded-md d-flex">
                    <v-text-field
                      dense
                      hide-details
                      filled
                      suffix="m²"
                      @input="toggle"
                      type="number"
                      v-model="estudio.autoestudio.superficie_disponible"
                    ></v-text-field>
                  </v-card>
                </v-item>
              </v-item-group>
            </aux-input>
            <v-divider />
            <aux-input title="Inclinación">
              <v-text-field
                filled
                dense
                hide-details="auto"
                type="number"
                v-model="estudio.inclinacion"
                suffix="º"
                hint="Por defecto: 20º"
                persistent-hint
              ></v-text-field>
            </aux-input>
            <v-divider />
            <aux-input title="Azimut">
              <v-text-field
                filled
                dense
                hide-details="auto"
                type="number"
                v-model="estudio.azimut"
                suffix="º"
                hint="Por defecto: 45º"
                persistent-hint
              ></v-text-field>
            </aux-input>
            <v-divider />
            <aux-input title="Precio medio kWh">
              <v-text-field
                filled
                dense
                hide-details="auto"
                type="number"
                v-model="estudio.autoestudio.precio_medio"
                suffix="€ x kWh"
                hint="Por defecto: 0.300 € x kWh"
                persistent-hint
              ></v-text-field>
            </aux-input>
            <v-divider />
            <template v-if="$root.acceso('EDITAR_ESTUDIOS')">
              <aux-input title="Precio Wp">
                <v-text-field
                  filled
                  dense
                  hide-details="auto"
                  type="number"
                  v-model="estudio.autoestudio.precio_wp"
                  suffix="€ x Wp"
                ></v-text-field>
              </aux-input>
              <v-divider />
            </template>
            <aux-input title="Aplicar IVA">
              <v-checkbox
              filled
                dense
                hide-details="auto"
                v-model="estudio.autoestudio.aplicar_iva"
                ></v-checkbox>
              </aux-input>
            <v-divider />
            <aux-input title="Incluir financiacion">
              <v-checkbox
                filled
                dense
                hide-details="auto"
                v-model="estudio.autoestudio.calcular_financiacion"
              ></v-checkbox>
            </aux-input>
            <v-divider v-if="$root.acceso('EDITAR_ESTUDIOS')" />
            <aux-input
              title="Interes anual"
              v-if="$root.acceso('EDITAR_ESTUDIOS')"
            >
              <v-text-field
                filled
                dense
                hide-details="auto"
                type="number"
                v-model="estudio.autoestudio.interes"
                suffix="%"
                :disabled="!estudio.autoestudio.calcular_financiacion"
              ></v-text-field>
            </aux-input>
            <v-divider />
            <aux-input title="Años financiacion">
              <v-text-field
                filled
                dense
                hide-details="auto"
                type="number"
                v-model="estudio.autoestudio.años_financiacion"
                hint="Por defecto: 10 años"
                persistent-hint
                :disabled="!estudio.autoestudio.calcular_financiacion"
              ></v-text-field>
            </aux-input> -->
          </tarjeta-datos>
        </v-col>
        <v-col cols="12" md="5">
          <tarjeta-datos header titulo="Mapa" icon="map">
            <div style="min-height: 500px; display: grid">
              <Map
                v-show="estudio.cups_principal.direccion"
                ref="map"
                :center="{
                  lat: Number(estudio.latitud || 0),
                  lng: Number(estudio.longitud || 0),
                }"
                :zoom="18"
                v-model="estudio.cubiertas"
                :marker="estudio.freezePosition"
                @changePosition="
                  ({ latitud, longitud }) => {
                    estudio.latitud = latitud;
                    estudio.longitud = longitud;
                    estudio.freezePosition = true;
                  }
                "
              />
            </div>
          </tarjeta-datos>
        </v-col>
      </v-row>
    </v-form>

    <v-dialog :value="error" max-width="500">
      <v-card>
        <v-alert class="ma-0" type="error" outlined text>
          {{ error }}
        </v-alert>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import {
  req,
  cups,
  isNumber,
  ValidateSpanishID,
  spainIdType,
} from "@/utils/validations.js";

export default {
  components: {
    TarjetaDatos: () => import("@/components/TarjetaDatos.vue"),
    AuxInput: () => import("@/components/AuxInput.vue"),
    Map: () => import("@/components/Map.vue"),
    EstudioExpress: () => import("../components/EstudioExpress.vue"),

  },
  data() {
    return {
      error: null,
      isLoading: false,
      creating: false,
      estudio: {
        idTipo: null,
        cliente: {
          contactos: [],
        },
        cups_principal: {},
        cubiertas: [],
        potencias: {},
        latitud: 0,
        longitud: 0,
        autoestudio: {
          tipo: "estudio_express",
          calcular_financiacion: true,
          curva: "SERVICIO_GENERAL",
          estructura: "COPLANAR",
          superficie_disponible: null,
          simulacion_vecinos: null,
        },
      },
      rules: {
        req,
        cups,
        isNumber,
        nombreCubierta: this.nombreCubierta,
        dni: ValidateSpanishID,
      },
      selects: {
        tarifas: [],
        tipoContactos: [
          { icon: "mdi-email", text: "Email", tipo: "E" },
          { icon: "mdi-phone", text: "Telf.", tipo: "T" },
        ],
        nivelesInteres: [],
        curvas: [
          { text: "Residencial", value: "RESIDENCIAL", icon: "mdi-home" },
          {
            text: "Servicio general",
            value: "SERVICIO_GENERAL",
            icon: "mdi-office-building",
          },
          { text: "Industrial", value: "INDUSTRIAL", icon: "mdi-factory" },
        ],
        estructura: [
          { text: "Coplanar", value: "COPLANAR" },
          { text: "Otras", value: "OTRAS" },
        ],
      },
      curvas_horarias: {
        RESIDENCIAL: [
          0.034, 0.03, 0.025, 0.0235, 0.024, 0.024, 0.028181818, 0.032272727,
          0.037272727, 0.040909091, 0.046, 0.049, 0.051, 0.053, 0.053955, 0.051,
          0.0455, 0.0455, 0.049, 0.050909091, 0.053, 0.056, 0.052, 0.045,
        ],
        SERVICIO_GENERAL: [
          0.032619776, 0.031396534, 0.03058104, 0.029357798, 0.029357798,
          0.03058104, 0.032212029, 0.035881753, 0.046890928, 0.0509684,
          0.053007136, 0.054230377, 0.055045872, 0.053007136, 0.048114169,
          0.046075433, 0.044852192, 0.044852192, 0.045463812, 0.046075433,
          0.044852192, 0.041590214, 0.038328236, 0.034658512,
        ],
        INDUSTRIAL: [
          0.003496503, 0.003496503, 0.003496503, 0.003496503, 0.006993007,
          0.003496503, 0.003496503, 0.006993007, 0.094405594, 0.101398601,
          0.101398601, 0.094405594, 0.094405594, 0.059440559, 0.020979021,
          0.076923077, 0.073426573, 0.083916084, 0.083916084, 0.048951049,
          0.013986014, 0.006993007, 0.006993007, 0.003496503,
        ],
      },
    };
  },
  methods: {
    async buscarDireccion() {
      this.isLoading = true;
      try {
        if (this.estudio.freezePosition) {
          this.isLoading = false;
          return;
        }
        const { lat, lng } = await this.$refs.map.buscarDireccion(
          this.estudio.cups_principal.direccion
        );
        this.estudio.latitud = lat;
        this.estudio.longitud = lng;
      } catch (err) {
        console.error(err);
      }
      this.isLoading = false;
    },
    async buscarCups() {
      if (
        this.estudio.cups_principal.cups == "" ||
        this.estudio.cups_principal.cups == null ||
        this.rules.cups(this.estudio.cups_principal.cups) !== true
      )
        return;
      this.isLoading = true;

      const { data } = await axios({
        url: `${process.env.VUE_APP_API_URL}/cups/datos`,
        params: { cups: this.estudio.cups_principal?.cups },
      }).catch((err) => {
        this.$root.$emit(
          "snack",
          "Ha ocurrido un error al buscar los datos del suministro",
          "error"
        );
        this.isLoading = false;
        return;
      });

      // this.estudio.cups_principal.direccion = `${data.Localidad_Suministro} ${data.Direccion_Suministro}`;

      this.estudio.idTarifa = this.selects.tarifas.find((tarifa) =>
        data.Tarifa.includes(tarifa.text)
      )?.idTarifa;

      const auxTarifa = this.selects.tarifas.find((tarifa) =>
        data.Tarifa.includes(tarifa.text)
      );

      Array.from(
        { length: auxTarifa.periodosEnergia },
        (_, i) => `p${i + 1}`
      ).forEach((periodo) => {
        this.$set(this.estudio.potencias, periodo, {
          potencia: data[`Pot_Cont_${periodo.toUpperCase()}`],
          estimado: data[`kWhAnual_${periodo}`],
          precio: this.estudio?.potencias?.[periodo]?.precio,
        });
      });

      this.estudio.consumoAnualSips = data.kWhAnual;
      this.isLoading = false;
      return;
    },
    async getTarifas() {
      try {
        const { data } = await axios({
          method: "get",
          url: `${process.env.VUE_APP_API_URL}/tarifas`,
        });
        this.selects.tarifas = data.map((t) => ({
          ...t,
          text: t.nombreTarifa,
          value: t.idTarifa,
        }));
      } catch (e) {
        console.log(e);
        this.$root.$emit(
          "snack",
          "Ha ocurrido un error al recuperar las tarifas"
        );
      }
    },
    async generarEstudio() {
      if (!this.$refs.formulario.validate()) return;

      this.creating = true;
      this.error = null;

      try {
        const { data } = await axios({
          method: "POST",
          url: `${process.env.VUE_APP_API_URL}/estudios`,
          data: {
            ...this.estudio,
            cubiertas: this.estudio.cubiertas
              .filter((c) => c.polygon.visible)
              .map((c) => ({ ...c, polygon: c.polygon.getPath().getArray() })),
            estado: "Estudio Listo",
            idTipo: 1,
          },
        });

        this.$router.push(`/detalles_estudio?idEstudio=${data.idEstudio}`);
      } catch (err) {
        let error = err.response.data.detail;
        if (!error) {
          this.$root.$emit("snack", "Ha ocurrido un error inesperado");
        } else {
          this.error = error;
        }
      } finally {
        this.creating = false;
      }
    },
  },
  mounted() {
    this.getTarifas();
  },
  computed: {
    superficieDisponible() {
      return this.estudio?.cubiertas.reduce(
        (p, a) => p + parseFloat(a.area),
        0
      );
    },
  },
};
</script>

<style>
.item-selection {
  display: grid !important;
  grid-template-rows: 1fr 0fr;
  text-align: center;
}

.v-item--active {
  color: var(--v-primary-base) !important;
  border-color: var(--v-primary-base) !important;
}
</style>
